.privacy-policy{
        width: 90%;
        margin: 0 auto;
}

.privacy-page1{
        margin: 2rem 0 3rem 0;
        padding-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: 2rem;
        border-bottom: 1px solid #E4E4E7;
}

.privacy-page1>img{
    width: 500px;
    border-radius: 4px;
}

.privacy-page1>section{
    max-width: 500px;
}

.privacy-page1>section>h1{
    font-size: 30px;
    font-weight: 700;
}

.privacy-page1>section>p{
    margin: 2rem 0;
    font-size: 16px;
    font-weight: 500;
    color: #333;
    text-align: justify;
}

.privacy-page2{
    margin: 2rem 0 3rem 0;
}

.privacy-page2>h1{
    font-size: 30px;
    font-weight: 700;
}

.certificate-page1{
    margin: 2rem 0;
}

.certificate-page1>h1{
    font-size: 30px;
    font-weight: 700;
    text-align: center;
}

.privacy-page2 p{
    padding: 0 1rem;
    margin: 0.5rem 0 2rem 0;
    font-size: 16px;
    font-weight: 500;
    color: #333;
    text-align: justify;
}

.privacy-page2>section{
    margin: 2rem 0;
}

.privacy-page2 h2{
    font-size: 25px;
    font-weight: 700;
}

.privacy-page2>section>ul{
    padding: 0 2rem;
    list-style: square;
    margin: 1rem 0;
    font-size: 16px;
    font-weight: 500;
    color: #333;
}

.privacy-page2>section>ul>li{
    margin: 1rem 0;
}

.privacy-policy .graduation-img{
   width: 400px !important;
}

.certificate-page1 section{
margin: 3rem 0 1rem 0;
display: flex;
align-items: center;
justify-content: space-evenly;
flex-wrap: wrap;
gap: 2rem;
}

.certificate-page1 section div{
    width: 500px;
    box-shadow: 1px 1px 2px 2px #afafaf;
    padding: 1rem;
    border-radius: 6px;
    text-align: center;
}

.certificate-page1 section div img{
    width: 100%;
}

.certificate-page1 section div p{
    padding: 0 1rem;
    margin: 1rem 0;
    font-size: 16px;
    font-weight: 500;
}