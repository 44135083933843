.contact-page{
    width: 90%;
    margin: 0 auto;
}

.contact-page1{
    margin: 2rem 0 3rem 0;
    padding-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 2rem;
    border-bottom: 1px solid #E4E4E7;
}



.contact-page1 h1{
    font-size: 30px;
    font-weight: 700;
}

.contact-page1 p{
    margin: 2rem 0;
    font-size: 16px;
    font-weight: 500;
    color: #333;
    max-width: 500px;
    text-align: justify;
}

.contact-page2{
    background-color: #fff;
    box-shadow: 1px 1px 2px 2px #E9F1FD;
    border-radius: 4px;
    margin: 3rem 0;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.contact-page2 form{
    width: 500px;
    padding-top: 2rem;
}


.contact-page2 form input[type="date"] {
    /* Example of styling */
    appearance: auto; /* Use the default appearance */
}




.contact-page2 form input,.contact-page2 form select{
    border: 1px solid #E4E4E7;
    width: 100%;
    padding: 0.75rem;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    border-radius: 4px;
    background-color: transparent;
    color: black;
    outline: none;
}



.contact-page2 form input:hover ,  .contact-page2 form textarea:hover {
    border: 1px solid #0163FD50;
}

.contact-page2 form input:focus , .contact-page2 form textarea:focus{
    border: 1px solid #0163FD70;
    box-shadow: 0 0 2px 2px #0163FD30;
}

.contact-page2 form textarea{
    border: 1px solid #E4E4E7;
    width: 100%;
    padding: 1rem;
    margin-top: 0.3rem;
    margin-bottom: 1rem;
    border-radius: 4px;
    outline: blue;
    height: 200px;
    resize: vertical;
}

.contact-page2 form div>p{
    text-align: left;
    position: relative;
    bottom: 1rem;
    left: 0.5rem;
    font-size: 14px;
    font-weight: 500;
    color: #f45b5b;
}

.contact-page2 form label{
    font-size: 14px;
    font-weight: 600;
    color: #333;
}

/* .contact-page2 form p:nth-last-child(1){
display: flex;
align-items: center;
justify-content: space-evenly;
} */

.contact-page2 form button{
    display: block;
    margin: 1rem auto;
    padding: 0.75rem 2rem;
    background-color: #0163FD;
    color: white;
    border-radius: 4px;
    font-weight: 500;
    border: none;
    font-weight: 500;
    width: 100%;
    font-size: 18px;
}

.contacts{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column;
    gap: 2rem;
    background-color: white;
    padding: 2rem 0;
    border-radius: 4px;
}

.contacts section{
    width: 300px;
    height: auto;
    border: 1px solid #E4E4E7;
    border-radius: 4px;
    padding: 1rem 0;
    transition: all 0.2s ease;
}


.contacts section main{
    margin: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.contacts section main>img{
    height: 45px;
    width: 45px;
}

.contacts section p, .contacts section a{
    margin: 2rem 0;
    text-align: center;
    color: #4C4C4D;
    font-weight: 500;
    font-size: 16px;
}

.contacts section p>a{
    text-decoration: none;
}

.contact-text1{
    margin: 3rem 0;
    text-align: center;
}

.contact-text1 h1{
  font-size: 30px;
  font-weight: 700;
  margin: 1rem 0;
}

.contact-text1>p{
    color: #333;
    font-size: 16px;
    font-weight: 500;
}


/* Phone input */

/* .custom-container {
   
    justify-content: center;
    margin: 10px;
  } */

  .react-tel-input .form-control{
    position: relative;
    letter-spacing: .01rem;
    /* margin-top: 0 !important;
    margin-bottom: 0 !important; */
    padding-left: 60px;
    /* margin-left: 0; */
    background: transparent;
    border: 1px solid #E4E4E7;
    border-radius: 4px;
    line-height: 25px;
    height: 55px;
    width: 100%;
    outline: none;
  }
  
  .react-tel-input .flag-dropdown.open .selected-flag {
    background-color: transparent;
    border-radius: 4px 0 0 0;
}
.react-tel-input .selected-flag {
    outline: none;
    position: relative;
    width: 50px;
    height: 100%;
    padding: 0 0 0 8px;
    border-radius: 4px 0 0 4px;
    background-color: white;
}

.react-tel-input .flag-dropdown{
    border: 1px solid #E4E4E7;
}


/* Success message */
  .success-message{
    height: 100%;
    width: 100%;
    overflow :hidden;
    background-color: rgb(0,0,0,0.2);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .success-message.active{
    display: none;
  }


  .success-container{
    max-width: 500px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 0 5px #E4E4E7;
    padding: 1rem;
  }

  .success-container p{
    margin: 1rem 0;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
  }

  .success-container p a{
    color: #0163FD;
    text-decoration: underline;
  }

  .success-container img{
    position: fixed;
    top: 10%;
    right: 10%;
    cursor: pointer;
  }


  .location-container{
    margin: 2rem 0;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 2rem;
  }

  .location-container>section{
    background-color: #fff;
    border-radius: 6px;
    padding: 48px;
    width: 455px;
    min-height: 350px;
    box-shadow: 1px 2px 4px 4px #0163FD10;
    text-align: center;
  }

  .location-container>section>h1{
    font-size: 35px;
    font-weight: bold;
  }

  .location-container>section>p{
    margin: 1.3rem 0;
    display: flex;
    align-items: center;
    gap: 2rem;
    width: 100%;
    text-align: center;
    font-weight: 400;
    line-height: 1.5;
  }

  .location-container>section>p>span{
    display: block;
    text-align: left;
    width: 100%;
  }

  /* .location-container>section>.location::before{
    content: "";
    flex-shrink: 0;
    display: inline-block;
    width: 16px;
    aspect-ratio: 20 / 20;
    margin-right: 16px;
    background: url(../img/LocationSvgIcon.png) no-repeat;
    background-size: contain;
    background-position: center;
  }

  .location-container>section>.email::before{
    content: "";
    flex-shrink: 0;
    display: inline-block;
    width: 16px;
    aspect-ratio: 20 / 20;
    margin-right: 16px;
    background: url(../img/MailSvgIcon.png) no-repeat;
    background-size: contain;
  }

  .location-container>section>.phone::before{
    content: "";
    flex-shrink: 0;
    display: inline-block;
    width: 16px;
    aspect-ratio: 20 / 20;
    margin-right: 16px;
    background: url(../img/phoneSvgIcon.png) no-repeat;
    background-size: contain;
  } */

  .location-container>section>p>img{
    width: 20px;
  }


/*  */

@media (max-width:768px) {
    .contact-page2 form{
        width: 100%;
    }

    .contact-page1>img{
        width: 100%;
    }
}


@media (min-width:768px){
    .contact-page1 img{
        width: 35%;
    }

}