.footer{
    background-color: #0163FD;
    margin-top: 3rem;
    height: auto;
    width: 100%;
    color: white;
    font-size: 16px;
    font-weight: 500;
}

.footer>.footer-section{
    width: 90%;
    margin: 0 auto;
    padding: 1rem 0;
}

.footer>.footer-section>.logo{
   margin-top: 1rem;
   margin-bottom: 2rem;
}


.footer-logo{
    margin: 1rem 0;
    font-size: 35px;
    font-weight: 500;
    color: white;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.5rem;
}



.footer-main1{
    margin: 1rem 0;
    display: flex;
    align-items: self-start;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
}

.footer-main1>p{
    width: 400px;
    text-align: justify;
    /* color: #000080; */
}

/* .footer-main1>section{
    width: 300px;
} */
/* 
.footer-main2{
    margin: 1rem 0;
} */

.footer-main2>section>h1{
    font-size: 20px;
    font-weight: 600;
}

.footer-main2>section>p>a{
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 0.5rem;
}

.footer-main2>section>p{
    margin: 0.85rem 0;
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 0.5rem;
}

.footer-main2>section>p>a>img,.footer-main2>section>p>img{
    height: 25px;
    width: 25px;
}


.footer-main1>main{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.footer-main1 ul{
    width: 250px;
    list-style: none;
}

.footer-main1 ul li h1{
    font-size: 20px;
    font-weight: 600;
}

.footer-main1 ul li>a{
    display: block;
    padding: 0.5rem 0 0.5rem 0.75rem;
    color: white;
}

.last-section{
    margin-top: 1rem;
    border-top: 1px solid #F7F7F8;
}

.last-section>p{
    padding-top: 1rem;
    text-align: center;
    font-size: 14px;
}

.socials{
    margin: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: left;
}

.socials>a{
    margin-right: 1.5rem;
}

.socials>a>img{
    height: 40px;
    width: 40px;
}

@media (min-width: 481px) and (max-width: 1000px) {
    .footer-main1>p{
        width: 100%;
    }
}


@media (max-width: 767px) {

    .socials{
        justify-content: space-between;
    }

    .socials>a{
        margin-right: 0;
    }
}