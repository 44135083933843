@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

html {
  font-family: "Poppins", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #F7F7F8;
}

.booking-button{
  margin: 3rem 0;
}

.booking-button a{
  display: inline-block;
  text-align: center;
  background-color: #0163FD;
  padding: 0.75rem;
  font-size: 18px;
  font-weight: 600;
  color: white;
  border-radius: 4px;
  width: 250px;
}



/* Home CSS */
.main-container {
  width: 90%;
  margin: 0 auto;
}

.section1{
  padding:3rem 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;
}

.responsive-img {
  width: 100%; /* This makes the image take the full width of its container */
  max-width: 673px; /* Set a maximum width to prevent the image from becoming too large */
  height: auto; /* This maintains the aspect ratio */
}

.section1 h2 {
  font-size: 22px;
  font-weight: 700;
  padding: 1rem 0;
}

.section1 p{
  font-size: 16px;
}


.company .Img2{
  display: block;
}

.company .Img3{
  display: none;
}

.section2{
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 2rem 0 3rem 0;
  gap: 2rem;
}

.section2 .text1{
  max-width: 500px;
}

.section2 .text1 h3{
  font-size: 30px;
  font-weight: bold;
  margin: 0.5rem 0;
}

.section2 .text1 h3>span{
  color: #0163FD;
}

.section2 .text1 h1{
  font-size: 16px;
  margin: 0.5rem 0;
  font-weight: bolder;
}

.section2 .text1 p{
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  margin: 1rem 0;
  text-align: justify;
}

.section3{
  margin: 5rem 0;
}


.section3>p{
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.section3 h1{
  text-align: center;
  font-size: 16px;
  margin: 0.5rem auto;
  font-weight: 500;
}

.grid1{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 3rem;
    padding: 3rem 0;  
 
}

.grid1 div{
  height: auto;
  width: 350px;
  padding: 1rem;
  border-radius: 4px;
  position: relative;
  transition: all 0.2s ease-in-out;
  background-color: white;
  box-shadow: 1px 1px 2px 2px #E9F1FD;
}

.grid1 div:hover{
  background-color: #E9F1FD;
}

.grid1 div h1{
  font-size: 45px;
  text-align: left;
  margin: 0;
  font-weight: 700;
  color: #333333;
}

.grid1 div h2{
 font-size: 20px;
 font-weight: bold;
}

.grid1 div p{
  font-size: 16px;
  color: #333333;
  margin:1rem 0;
  font-weight: 500;
  text-align: justify;
}

.grid1 div img{
width: 20%;
}

.section4{
  margin: 3rem 0;
}

.section4>h1{
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.section4>p{
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin: 0.5rem 0;
}



.section4 .grid2{
  margin: 0 auto;
  padding: 3rem 0;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  row-gap: 3rem;
}

.section4 .grid2 section{
  height: auto;
  width: 500px;
  padding: 1rem;
  border-radius: 4px;
  background-color: white;
  box-shadow: 1px 1px 2px 2px #E9F1FD;
}

.section4 .grid2 section img{
  width: 100%;
}

.section4 .grid2 section h1{
  margin-top: 1rem;
  font-size: 20px;
  text-align: left;
  font-weight: bold;
}



.section4 .grid2 section p{
  margin-top: 1rem;
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  text-align: justify;
}

/* youtube video */
.main-container .section4 .youtube-video{
  margin: 2rem auto 0 auto;
}

.main-container .section4 .youtube-video .techblume-video{
  margin: 0 auto;
  width: 700px;
  height: 396px;
}


/* Responsive adjustments for smaller screens */
@media (max-width: 468px) {
  .main-container .section4 .youtube-video  .techblume-video{
    max-width: 100%; /* Allow full width on smaller screens */
    height: 197px;
  }
}

@media (min-width: 469px) and (max-width:768px) {
  .main-container .section4 .youtube-video {
    max-width: 100%; /* Allow full width on smaller screens */
  }
}

.section2 .text1 h5{
  font-size: 35px;
  font-weight: bold;
}

.section2 .text1 h6{
  font-size: 16px;
  padding: 1rem 0;
  font-weight: 500;
  text-align: justify;
}

.section2 .text1 h5 span{
  color: #0163FD;
}

.section2 .icons{
  display: flex;
  align-items: center;
  column-gap: 1rem;
  margin: 1rem 0;
}

.section2 .icons>img{
  width: 15%;
}

.section2 .icons>.icon-txt{
  font-size: 25px;
  font-weight: 700;
  color: #333333;
}





/* Media Query */
/* Mobile view */
@media (max-width:480px){
  /* .section3 h1{
    width: 80%;
  } */

  .section2 .icons>.icon-txt {
    font-size: 22px;
  }
}

@media (max-width: 768px) {
  .company .Img2{
   display: none;
  }

.company .Img3{
  display: block;
}

.footer ul{
  flex-direction: column;
}

}

@media (min-width: 1200px) {
  .responsive-img {
    max-width: 35%; /* Adjust the maximum width for very large screens */
  }
}

@media (min-width: 768px){
  .section2 .Img4{
    width: 40%;
  }

  .section2 .icons>img{
    width: 10%;
  }
}


/* Contact form */
.contact-form{
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 2000;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: space-evenly;

}

.contact-form.active{
  display: none;
}

.contact-form>main{
  margin: 0 0.5rem;
  width: 550px;
  padding: 1rem;
  background-color: #eff1f3;
  /* border: 1px solid #888; */
  border-radius: 5px;
}

.contact-form>main>h1{
  margin: 1rem 0;
  font-size: 25px;
  text-align: center;
  font-weight: bold;
  color: #555;
}

.contact-form>main>form{
  background-color: white;
  padding: 1rem;
  margin: 1rem auto;
  border-radius: 4px;
}


.contact-form>main>form label{
  font-size: 14px;
  font-weight: 600;
  color: #333;
}

.contact-form>main>form .phone-input{
  position: relative;
}

.contact-form>main>form input{
  border: 1px solid #E4E4E7;
  width: 100%;
  padding: 0.75rem;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  border-radius: 4px;
  background-color: transparent;
  outline: none;
  color: black;
}

.contact-form>main>form input:hover{
  border: 1px solid #0163FD70;
}

.contact-form>main>form input:focus{
  border: 1px solid #0163FD70;
  box-shadow: 0 0 2px 2px #0163FD30;
}

.contact-form>main>form .phone-input>select{
  position: absolute;
  top: 15px;
  left: 5px;
  width: 110px;
  font-size: 16px;
  padding: 0.5rem;
  font-size: small;
  background-color: transparent;
  
}

.contact-form>main>form .phone-input>input{
  padding-left: 120px;
}

.contact-form>main>form button{
  display: block;
  margin: 1rem auto;
  padding: 0.75rem 2rem;
  background-color: #0163FD;
  color: white;
  border-radius: 4px;
  font-weight: 500;
  border: none;
  font-weight: 500;
  width: 100%;
  font-size: 18px;
}



/* loader css */

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  z-index: 9999; /* Ensure it is on top */
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #0163FD; /* Blue */
  border-radius: 50%;
  width: 50px; /* Size of the loader */
  height: 50px; /* Size of the loader */
  animation: spin 1s linear infinite; /* Animation */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}







/* alumini section */

.section4>.alumini-section{
  margin: 2rem 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.section4>.alumini-section>div{
  width: 250px;
  padding: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  background-color: white;
  box-shadow: 1px 1px 2px 2px #E9F1FD;
  text-align: center;
}

.section4>.alumini-section>div>img{
  height: 175px;
  width: 175px;
}

.section4>.alumini-section>div>h1{
  margin-top: 1rem ;
  color: #0163FD;
  font-weight: bold;
}

.section4>.alumini-section>div>h3{
  font-size: small;
  font-weight: 500;
}

.section4>.alumini-section>div>p{
  margin-top: 1rem;
  font-size: smaller;
  color: #333;
}

.section4>.alumini-section>div>h4{
  margin-top: 1rem;
  font-weight: 500;
}

@media screen and (max-width: 768px) {
  .section4>.alumini-section>div{
    width: 320px;
  }
}

