.Booking-page{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: #F7F7F8;
    margin: 0 auto;
}

.booking-section1{
    width: 50%;
    margin: 1rem auto;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}



.booking-section1 img{
   width: 100px;
   height: 100px;
}

.booking-section1>section>h2{
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 0.3rem;
}

.booking-section1>p{
    font-size: 18px;
}

.booking-section2{
    background: #fff; 

}



/* Booking form */


.booking-section2 form{

    max-width: 500px;
    margin: 1rem auto;
    padding: 2rem;
    background-color: #fff;
    border-radius: 4px;
}

.booking-section2 form>h1{
    font-size: 28px;
    font-weight: 700;
    text-align: center;
    margin: 0rem 0 0.5rem 0;
}

.booking-section2 form>p{
    text-align: center;
    margin: 0rem 0 2rem 0;
}


.booking-section2 form input[type="date"] {
    /* Example of styling */
    appearance: auto; /* Use the default appearance */
}




.booking-section2 form input,.booking-section2 form select{
    border: 1px solid #E4E4E7;
    width: 100%;
    padding: 0.75rem;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
    border-radius: 4px;
    background-color: transparent;
    outline: none;
    color: black;
}

.booking-section2 form input:focus{
    box-shadow: 0px 0px 4px #E4E4E7;
}

.booking-section2 form input:hover  {
    border: 1px solid #0163FD50;
}

.booking-section2 form input:focus {
    border: 1px solid #0163FD70;
    box-shadow: 0 0 2px 2px #0163FD30;
}

.booking-section2 form textarea{
    border: 1px solid #E4E4E7;
    width: 100%;
    padding: 1rem;
    margin-top: 0.3rem;
    margin-bottom: 1rem;
    border-radius: 4px;
    outline: blue;
    height: 200px;
    resize: vertical;
}

.booking-section2 form div>p{
    text-align: left;
    position: relative;
    bottom: 1rem;
    left: 0.5rem;
    font-size: 14px;
    color: rgb(250, 54, 54);
}

.booking-section2 form label{
    font-size: 14px;
    font-weight: 600;
    color: #333;
}

.booking-section2 .phone-input{
    position: relative;
}

.booking-section2 .phone-input>select{
    position: absolute;
    top: 0;
    width: 100px;
    font-size: 16px;
    padding: 0.85rem 0.5rem;
    font-size: small;
    border: none;
    
}

.booking-section2 .phone-input>input{
    padding-left: 120px;
}

/* .contact-page2 form p:nth-last-child(1){
display: flex;
align-items: center;
justify-content: space-evenly;
} */

.booking-section2 form button{
    display: block;
    margin: 1rem auto;
    padding: 0.75rem 2rem;
    background-color: #0163FD;
    color: white;
    border-radius: 4px;
    font-weight: 500;
    border: none;
    font-weight: 500;
    width: 100%;
    font-size: 18px;
}

@media (max-width: 468px) {
    .booking-section1{
        width: 90%;
    }

    .booking-section2 .phone-input>select{
        width: 85px;
        
    }
    
    .booking-section2 .phone-input>input{
        padding-left: 100px;
        font-size: 16px;
    }
}


/* Custom Styling for Phone Input */
/* Custom styling for the wrapper */
.react-tel-input {
    font-size: 16px;
    border: 1px solid #E4E4E7;
    border-radius: 4px;
    padding: 0.45rem;
    width: 100%;
    background-color: transparent;
    margin-top: 0.5rem;
  }

 /* Remove outline and box-shadow on focus */
.react-tel-input .form-control:focus {
    outline: none;  /* Removes the outline */
    box-shadow: none;  /* Removes the box-shadow */
}


.react-tel-input:hover{
    border: 1px solid #0163FD70 !important;
    box-shadow: 0 0 2px 2px #0163FD30 !important;
}

  /* Custom input field */
  .react-tel-input .form-control {
    border: none;
    font-size: 14px;
    width: 100%;
    padding: 10px;
    background-color: transparent;
    border: none !important;
  }
  
  /* Custom dropdown */
  .react-tel-input .flag-dropdown {
    border: none !important;
  }
  

  /* On hover of flag dropdown */
  .react-tel-input .flag-dropdown:hover {
    background-color: #0056b3;
  }



  /* Custom CSs for Time Zone */
  /* Wrapper customization */
.timezone-select-wrapper {
    /* font-family: Arial, sans-serif; Customize font */
    font-size: 16px; /* Adjust text size */
    color: #000; /* Text color */
  }
  
  /* Dropdown select styling */
  .timezone-select-wrapper .react-select__control {
    border: 1px solid #E4E4E7; /* Border color */
    border-radius: 4px; /* Rounded corners */
    background-color: #fff; /* Background color */
    padding: 0.75rem; /* Add padding */
    box-shadow: none; /* Remove default box-shadow */
    transition: border-color 0.3s ease-in-out;
  }
  
  .timezone-select-wrapper .react-select__control:hover {
    border-color: none !important; /* Change border color on hover */
  }
  
  .timezone-select-wrapper .react-select__control--is-focused {
    border-color: #E4E4E7; /* Focus border color */
    box-shadow: none; /* Remove focus shadow */
  }
  
  /* Dropdown options */
  .timezone-select-wrapper .react-select__menu {
    border: 1px solid #E4E4E7; /* Border for the dropdown menu */
    border-radius: 4px; /* Rounded corners */
    background-color: transparent; /* Background color */
    z-index: 1000; /* Ensure dropdown appears above other elements */
  }
  
  .timezone-select-wrapper .react-select__option {
    padding: 8px 12px; /* Spacing inside options */
    cursor: pointer;
  }
  
  .timezone-select-wrapper .react-select__option:hover {
    background-color: transparent; /* Background on hover */
  }
  
  .timezone-select-wrapper .react-select__option--is-selected {
    background-color: #E4E4E7; /* Highlighted background */
    color: #fff; /* Highlighted text color */
  }

  .css-13cymwt-control{
    padding: 0.38rem;
    border-color: #E4E4E7 !important;
  }

  .css-13cymwt-control:hover{
    border-color: #E4E4E7 !important;
  }
  
  .css-b62m3t-container{
    margin-top: 0.5rem !important;
  }

  .css-13cymwt-control:hover{
    border: 1px solid #0163FD70 !important;
    box-shadow: 0 0 2px 2px #0163FD30 !important;
  }


  .react-tel-input .form-control{
    width: auto !important;;
  }


