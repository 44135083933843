.thankyou-container{
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    width: 100%;
    z-index: 2000;
    background-color: #F7F7F8;
    margin: 0 auto;
}

.thankyou-container>.sub-container1{
    height: 30vh;
    width: 100%;
    background-color: #0163FD;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

}

.thankyou-container>.sub-container1>.circle{
    height: 130px;
    width: 130px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.thankyou-container>.sub-container2{
    text-align: center;
    width: 90%;
    margin: 0 auto;
}

.thankyou-container>.sub-container2>h2{
    margin-top: 2rem;
    margin-bottom: 0.75rem;
    font-size: 35px;
    font-weight: bold;
    color: #0163FD;
}

.thankyou-container>.sub-container2>p{
    max-width: 380px;
    margin: 0 auto;
}

.thankyou-container>.sub-container2>h3{
    margin-top: 2rem;
    font-size: 28px;
    color: #000;
    font-weight: bold;
}

.thankyou-container>.sub-container2>table{
    margin: 1rem auto;
}

.thankyou-container>.sub-container3{
    margin: 1.5rem auto 1.5rem auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.thankyou-container>.sub-container3>a{
    background-color: #0163FD;
    padding: 0.75rem 1.5rem;
    font-size: 18px;
    font-weight: 500;
    color: white;
    border-radius: 4px;
}